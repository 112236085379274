import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Portfolio/Portfolio.css";

function PortfolioWebsite() {
    return (
        <div>

            <div>
                <Container>
                    <Row className="ports">
                        <Col>
                            <div>
                                <h1>Welcome to Digital Triumph, a leading IT company that specializes in providing cutting-edge solutions for websites, e-commerce sites, custom ERP, and CRM applications. With a team of skilled professionals and a passion for innovation, we strive to deliver high-quality products and services that cater to our clients' unique business needs. Our portfolio showcases some of the remarkable projects we have successfully delivered, reflecting our commitment to excellence and customer satisfaction.</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="portfoliocontainer">
                    <Row>
                        <Col lg={4} className="portfoliopadding">
                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/anicut.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>ANICUT</h3>
                                    <p>Anicut helps Medical Billing Companies scale and profit. Expert billing with tech-driven precision. Based in Michigan, excelling in coding and revenue cycle management for U.S. firms.</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/menlyhome.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>MENLY</h3>
                                    <p>Where Style Meets Substance. Explore a curated range of men's fashion essentials that blend trendiness with comfort. Elevate your wardrobe with confidence at Menly.</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/saidigital.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>SAI DIGITAL</h3>
                                    <p>Sai Digital drives CX-focused business transformation, leveraging design thinking and solution architecture for scalability. Their global presence supports brands across industries with operational efficiencies, spanning Vietnam to Japan</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/stegen.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>STEIGEN SYNERGY</h3>
                                    <p>STEIGEN SYNERGY IT PVT LTD in India empowers startups with end-to-end technology solutions. From ideation to execution, they transform innovative concepts into sustainable solutions within budgets, leveraging their expertise and resources. With proven software development, they guide startups to success by overcoming challenges and delivering impactful solutions.</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/lb.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>LAKSHMANAN BOJARAJAN</h3>
                                    <p>Lakshmanan Bojarajan's portfolio elevates fashion's role in self-expression, spanning age, size, and gender. With practical tips and color insights, he revitalizes wardrobes, nurturing confidence and distinct style. His mission is empowering others with standout outfits that exude personal confidence and flair.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} className="portfoliopadding portfoliopadding1">
                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/spry.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>SPRY COAT</h3>
                                    <p>Spry Coat Private Limited embodies a fusion of quality and engineering, addressing contemporary industry surface coating needs. Their commitment lies in offering budget-friendly, environmentally conscious solutions tailored to fulfill customer demands.</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/yamaha.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>WALTERS YAMAHA</h3>
                                    <p>Walters Yamaha stands as an authorized dealership for India Yamaha Motors Private Limited, offering a comprehensive 3S experience encompassing Sales, Service, and Spares. Renowned as one of Tamil Nadu's leading Yamaha dealerships, Walters Yamaha proudly operates six flagship outlets strategically situated across Chennai's prime destinations.</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/rle.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>RLE INTERNATIONAL GROUP</h3>
                                    <p>The RLE INTERNATIONAL Group stands among global leaders, offering development, tech, and consultation to engineering sectors. Their 2,300 employees drive innovation, shaping the company's future</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/srmachennai.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>SRMA</h3>
                                    <p>SRMA, established in 2007, empowers surplus manufacturers and traders in Chennai. Guiding through economic shifts, it supports businesses with VAT, GST, and currency-related challenges, serving as a reliable mentor in evolving market dynamics.</p>
                                </div>
                            </div>

                            <div class="content my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/adithyagrp.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>ADITHYA GROUPS OF CONSTRUCTION</h3>
                                    <p>Trusted for high-quality residential projects. Expert team delivers tailored house construction services. From meticulous planning to open communication, they turn dreams into reality with craftsmanship and precision.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} className="portfoliopadding">
                            <div class="content  my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/syngryhive.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>SYNERGY HIVE</h3>
                                    <p>Synergy Hive pioneers digital marketing with tailored strategies in SEO, PPC, and social media. They enhance brand visibility, fostering lasting growth in the dynamic digital landscape. Elevate your brand's online presence with Synergy Hive.</p>
                                </div>
                            </div>


                            <div class="content  my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/psaindia.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>PSA</h3>
                                    <p>PSA International excels as a top port group, spanning 160 locations globally. They offer world-class port services, innovate cargo solutions, and collaborate for an Internet of Logistics, fostering seamless supply chains.</p>
                                </div>
                            </div>


                            <div class="content  my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/solarsys.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>SOLARSYS INNOVATIONS</h3>
                                    <p>SolarSys Innovations stands as a leading software firm, aiding businesses' digital evolution for enhanced engagement. With tailored solutions, they foster global growth through creative design and development, epitomizing ownership and focused leadership</p>
                                </div>
                            </div>

                            <div class="content  my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/krishpar.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>KRISHPAR</h3>
                                    <p> An embodiment of experience and innovation, offering integrated HR solutions. Elevate growth with tailored executive sourcing, recruitment, payroll, and compliance services. Krishpar propels you to new horizons with unwavering support.</p>
                                </div>
                            </div>

                            <div class="content  my-5">
                                <div class="content-overlay"></div>
                                <img width="100%" class="content-image" src={require("../../Assests/Portfolioimages/igproperty.jpg")} alt="" />
                                <div class="content-details fadeIn-right">
                                    <h3>IG PROPERTY</h3>
                                    <p> Luxe villas, diverse properties, lavish living. Trusted for seamless real estate deals. Experts in commercial and residential, personalized service. Leaders in rentals, optimal spaces, investments.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </div>

        </div>
    )
}

export default PortfolioWebsite;