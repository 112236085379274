import React, { useEffect } from "react";
import "./About.css";
import { Col, Row, Container } from "react-bootstrap";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import ScrollTrigger from "../Scrolltriger/Scrolltriger.js";
import Flip from "../Flip/Flip";
import BIRDS from "vanta/src/vanta.halo";
import { Helmet } from "react-helmet";


function About({ setPage }) {
  useState(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const currentPathname = window.location.pathname;
  const newPathname = "/aboutus";
  window.history.pushState({}, "", newPathname);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    BIRDS({
      el: "#birds",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      backgroundColor: 0x24262b,
    })
  }, [])

  return (
    <div className="aboutus">
      <Helmet>
        <title>About Us - Digital Triumph</title>
        <meta name="description" content="Empowering Small and Medium Enterprises with Technology-Driven Solutions." />
        <meta name="keywords" content="Website Development Service in Anna Nagar, Mobile Application Development  Service in Anna Nagar, Software Development Service in Anna Nagar" />
        <link rel="canonical" href="https://digitaltriumph.in/aboutus" />
        <meta name="robots" content="index, follow" />
        <link rel="alternate" href="https://digitaltriumph.in/aboutus" hreflang="en" />
        <meta property="og:title" content="About Us - Digital Triumph" />
        <meta property="og:description" content="Empowering Small and Medium Enterprises with Technology-Driven Solutions." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://digitaltriumph.in/aboutus" />

      </Helmet>
      <div>

        <div className='bannersection'>

          <div style={{ height: "100vh" }} className='bg' id='birds'>
            <Container>
              <Row>
                <Col lg={12} className='column'>

                  <div className='bannertitle' >
                    <p>About us</p>
                    <h1>Empowering Small and Medium Enterprises with Technology-Driven Solutions.</h1>
                  </div>
                </Col>

              </Row>

            </Container>
          </div>

        </div>

        <Container>
          <Row>
            <div className="heading2">

              <h1>About Us</h1>
            </div>
          </Row>
          <div className="fig">
            <Row>
              <Col lg={6}>
                <p className="gif">
                  Our vision is to make small and medium business increase their
                  earning potential in an organic way with the help of suitable
                  technology available to them which is both cost effective,
                  tailor made for their needs, easy to use at the same time
                  increases the revenue & grow exponentially Facilitate
                  organizations recruit/deploy suitable manpower at affordable
                  cost and also engage in staff augmentation.
                </p>

              </Col>
              <Col lg={6}>
                <p className="gif">
                  We can help to maintain and modernize your IT infrastructure
                  and solve various infrastructure-specific issues a business
                  may face.
                </p>

                <div className="Contactbutton">
                  <a onClick={() => { setPage("contactus") }}><button>Contact Us Now</button></a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container>
          <Row>
            <Col lg={12}>
              <Flip />
            </Col>
          </Row>

        </Container>

      </div>
      <Container>
        <Row>
          <h1 className="bot">More About Us</h1>
        </Row>
        <Row>
          <Col>
            <section className="content yobject-marked">
              <div className="box bg-1">
                <a onClick={() => { setPage("team") }}>
                  <button
                    className="button button--nina button--text-thick button--text-upper button--size-s"
                    data-text="Team"
                  >
                    <span>T</span>
                    <span>e</span>
                    <span>a</span>
                    <span>m</span>
                  </button>
                </a>
              </div>
            </section>
          </Col>
          <Col>
            <section className="content yobject-marked">
              <div className="box bg-1">
                <a onClick={() => { setPage("career") }}>
                  <button
                    className="button button--nina button--text-thick button--text-upper button--size-s"
                    data-text="Career"
                  >
                    <span>C</span>
                    <span>a</span>
                    <span>r</span>
                    <span>e</span>
                    <span>e</span>
                    <span>r</span>
                  </button>
                </a>
              </div>
            </section>
          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default About;
