import React, { useEffect } from "react";
import Navbars from "../Navbar/Navbar";
import Typed from "react-typed";
import "./Team.css";
import { Container, Row, Col } from "react-bootstrap";
import BIRDS from "vanta/src/vanta.halo";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";


function Team({ setPage }) {

  useEffect(() => {
    BIRDS({
      el: "#birds",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      backgroundColor: 0x24262b,
    })
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const currentPathname = window.location.pathname;
  const newPathname = "/team";
  window.history.pushState({}, "", newPathname);

  return (
    <div className="team">
      <Helmet>
        <title>Team - Our Team turns ideas into reality, unlocking the full potential of the digital frontier.</title>
        <meta name="description" content="Our Team turns ideas into reality, unlocking the full potential of the digital frontier." />
      </Helmet>

      <div>
        <div className='bannersection'>

          <div style={{ height: "100vh" }} className='bg' id='birds'>
            <Container>
              <Row>
                <Col lg={12} className='column'>

                  <div className='bannertitle'>
                    <p>Our Team</p>
                    <h1>Our Team turns ideas into reality, unlocking the full potential of the digital frontier.</h1>
                  </div>
                </Col>

              </Row>

            </Container>
          </div>

        </div>
      </div>

      {/* <Container>
        <div className="animated-typing2">
          <h1>Our Leadership Team</h1>
        </div>
        <Row>
          <Col className="noope">
            <div className="ctn">
              <div className="hike">

                <div className="hike-overlay"></div>
                <img
                  className="hike-image"
                  src={require("../../Assests/mystyle/balaji.jpg")}
                />
                <div className="hike-details fadeIn-bottom">
                  <h3 className="hike-title">Balaji</h3>
                  <p className="hike-text">Managing Director</p>
                </div>
              </div>
            </div>

            <div className="ctn">
              <div className="hike">

                <div className="hike-overlay"></div>
                <img
                  className="hike-image"
                  src={require("../../Assests/mystyle/jayashree.jpg")}
                />
                <div className="hike-details fadeIn-bottom">
                  <h3 className="hike-title">Jayasree</h3>
                  <p className="hike-text">Managing Director</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <div className="animated-typing2">
            <h1>Our Development Team</h1>
          </div>
          <Col className="noope2" sm={12} lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/Ravi.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Ravi Kumar</h3>
                  <p className="hike-text2">Director-Operations</p>
                </div>

              </div>
            </div>
          </Col>
          <Col className="noope2" sm={12} lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/mari.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Mari Vignesh</h3>
                  <p className="hike-text2">Sr.Software Engineer</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" sm={12} lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image"
                  src={require("../../Assests/mystyle/tamil.jpeg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Tamil Selvi</h3>
                  <p className="hike-text2">Software Engineer</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" sm={12} lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/asif.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Asif</h3>
                  <p className="hike-text2">Software Engineer</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" sm={12} lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/ramkumar.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Ram Kumar</h3>
                  <p className="hike-text2">Software Engineer</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/karthi.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Karthikeyan</h3>
                  <p className="hike-text2">Software Engineer</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/nagesh.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Nageswar Rao</h3>
                  <p className="hike-text2">Software Engineer</p>
                </div>
              </div>
            </div>
          </Col>

          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image"
                  src={require("../../Assests/mystyle/selvam.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Selvam</h3>
                  <p className="hike-text2">Software Trainee</p>
                </div>

              </div>
            </div>
          </Col>

          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/rahul.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Raghul</h3>
                  <p className="hike-text2">Software Engineer</p>
                </div>
              </div>
            </div>

          </Col>

          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/harsha.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Harsha</h3>
                  <p className="hike-text2">Software Trainee</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="animated-typing2">
            <h1>Our HR Team</h1>
          </div>
          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/Richard.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Richardson</h3>
                  <p className="hike-text2">Lead HR IT  Recruiter</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/dhanalakshmi.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Dhana<br />lakshmi</h3>
                  <p className="hike-text2">HR IT Recruiter</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image"
                  src={require("../../Assests/mystyle/kanitha.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Kanitha</h3>
                  <p className="hike-text2">HR IT Recruiter</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/akshaya.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Akshaya</h3>
                  <p className="hike-text2">HR Executive</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="noope2" lg={3}>
            <div className="ctn2">
              <div className="hike2">

                <div className="hike-overlay2"></div>
                <img
                  className="hike-image2"
                  src={require("../../Assests/mystyle/rohit.jpg")}
                />
                <div className="hike-details2 fadeIn-bottom2">
                  <h3 className="hike-title2">Rohit</h3>
                  <p className="hike-text2">Digital Marketing Executive</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container>
        <Row>
          <h1 className="bot">More About Us</h1>
        </Row>
        <Row>
          <Col>
            <section className="content yobject-marked">
              <div className="box bg-1">
                <a onClick={() => { setPage("aboutus") }}>
                  <button
                    className="button button--nina button--text-thick button--text-upper button--size-s"
                    data-text="About us"
                  >
                    <span>A</span>
                    <span>b</span>
                    <span>o</span>
                    <span>u</span>
                    <span>t</span>
                    <span style={{ color: "transparent" }}>.</span>
                    <span>u</span>
                    <span>s</span>
                  </button>
                </a>
              </div>
            </section>
          </Col>
          <Col>
            <section className="content yobject-marked">
              <div className="box bg-1">
                <a onClick={() => { setPage("career") }}>
                  <button
                    className="button button--nina button--text-thick button--text-upper button--size-s"
                    data-text="Career"
                  >
                    <span>C</span>
                    <span>a</span>
                    <span>r</span>
                    <span>e</span>
                    <span>e</span>
                    <span>r</span>
                  </button>
                </a>
              </div>
            </section>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default Team;
