import React from "react";
import "../Map/Map.css";
import MapComponent from "./Maps";

function Map(){
    return(
        <div>
            <div className="map">
                <div>
                    <h1>50+ Happy Clients</h1>
                    <p>and Counting More....</p>
                </div>
                <div className="maps">
                    <MapComponent/>
                </div>
            </div>
        </div>
    )
};

export default Map;