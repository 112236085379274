import React, { useEffect, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "../Bannertech/Bannertech.css"
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css"

gsap.registerPlugin(ScrollTrigger);

function AnimatedGallery() {
    useEffect(() => {

        const details = gsap.utils.toArray(".desktopContentSection:not(:first-child)");
        const photos = gsap.utils.toArray(".desktopPhoto:not(:first-child)");

        gsap.set(photos, { yPercent: 101 });

        const allPhotos = gsap.utils.toArray(".desktopPhoto");

        const isDesktop = () => window.innerWidth >= 300;

        const animateDesktop = () => {
            

            ScrollTrigger.create({
                trigger: ".gallery",
                start: "top top",
                end: "bottom bottom",
                pin: ".right",
            });

            details.forEach((detail, index) => {
                let headline = detail.querySelector("h1");
                let animation = gsap.timeline().to(photos[index], { yPercent: 0 }).set(allPhotos[index], { autoAlpha: 0 });
                ScrollTrigger.create({
                    trigger: headline,
                    start: "top 5%",
                    end: "top 0%",
                    animation: animation,
                    scrub: true,
                    markers: false,
                });
            });
        };

        let initialIsDesktop = isDesktop();

        if (initialIsDesktop) {
            animateDesktop();
        }

        const handleResize = () => {
            const currentIsDesktop = isDesktop();
            if (currentIsDesktop && !initialIsDesktop) {
                animateDesktop();
            }
            initialIsDesktop = currentIsDesktop;
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useState(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <div className="bannertech">

            <Container>
                <div className="gallery">
                    <div className="left">
                        <div className="desktopContent">
                            <Row>
                                <Col>
                                <div className="desktopContentSection">
                                    <div className="bannerst">
                                        <div className="galleryh1">
                                            <img width="11%" src={require("../../Assests/icons/itservice1.gif")} />
                                            <h1 className="galleryh1img"> IT SERVICES</h1>
                                        </div>
                                        <div>
                                            <ul>
                                                <li data-aos="fade-right">Web Application Development</li>
                                                <li data-aos="fade-right">Mobile Application Development</li>
                                                <li data-aos="fade-right">Custom Software Development</li>
                                                <li data-aos="fade-right">Cloud Services & Data Management</li>
                                                <li data-aos="fade-right">CRM / ERP Development</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                </Col>
                            </Row>
                            <div className="desktopContentSection">
                                <div className="bannerst">
                                    <div className="galleryh1">
                                        <img width="11%" src={require("../../Assests/icons/business.gif")} />
                                        <h1 className="galleryh1img"> BUSINESS</h1>
                                    </div>
                                    <div>
                                        <ul>
                                            <li data-aos="fade-right">Branding</li>
                                            <li data-aos="fade-right">Branding Name</li>
                                            <li data-aos="fade-right">Brand Guidelines</li>
                                            <li data-aos="fade-right">Strategy</li>
                                            <li data-aos="fade-right">Digital Marketing</li>
                                            <li data-aos="fade-right">S.E.O</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="desktopContentSection">
                                <div>
                                    <div className="galleryh1">
                                        <img width="11%" src={require("../../Assests/icons/hrsolution.gif")} />
                                        <h1 className="galleryh1img"> HR SOLUTIONS</h1>
                                    </div>
                                    <div>
                                        <ul>
                                            <li data-aos="fade-right">Contract Staffing</li>
                                            <li data-aos="fade-right">Recruitment</li>
                                            <li data-aos="fade-right">Executive Search</li>
                                            <li data-aos="fade-right">Recruitment Process Outsourcing</li>
                                            <li data-aos="fade-right">Compliance Management</li>


                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="right">


                        <div className="desktopPhotos">
                            <div className="desktopPhoto red"></div>
                            <div className="desktopPhoto green"></div>
                            <div className="desktopPhoto pink"></div>
                        </div>

                    </div>
                </div>
            </Container>
            

        </div>
    );
}

export default AnimatedGallery;
