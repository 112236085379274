import React from "react";
import "../PrivacyPolicy/PrivacyPolicy.css"
import { Container } from "react-bootstrap";

function PrivacyPolicy() {

    const currentPathname = window.location.pathname;
    const newPathname = "/privacypolicy";
    window.history.pushState({}, "", newPathname);


    return (
        <div className="privacypolicy">
            <Container>
                <div>
                    <h1>PRIVACY POLICY</h1><br />
                    <h4>Digital Triumph Private Limited <br />Last updated:August 22,2023</h4><br />

                    <p>Digital Triumph Private Limited ("us", "we", or "our") operates the website
                        <a href="https://www.digitaltriumph.in"> https://www.digitaltriumph.in </a>(the "Service"). This page informs you of our policies
                        regarding the collection, use, and disclosure of personal information we receive from users
                        of the Service. We use your personal information only for providing and improving the Service. By using the
                        Service, you agree to the collection and use of information in accordance with this policy.</p><br />

                    <h4>Information Collection and Use:</h4>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable
                        information that can be used to contact or identify you. Personally identifiable information
                        may include, but is not limited to, your name, email address, phone number, and company
                        name ("Personal Information").</p><br />


                    <h4>Log Data:</h4>
                    <p>We collect information that your browser sends whenever you visit our Service ("Log Data").
                        This Log Data may include information such as your computer's Internet Protocol ("IP")
                        address, browser type, browser version, the pages of our Service that you visit, the time and
                        date of your visit, the time spent on those pages, and other statistics.</p><br />


                    <h4>Cookies:</h4>
                    <p>Cookies are files with a small amount of data, which may include an anonymous unique
                        identifier. Cookies are sent to your browser from a web site and stored on your computer's
                        hard drive.
                        We use "cookies" to collect information. You can instruct your browser to refuse all cookies
                        or to indicate when a cookie is being sent. However, if you do not accept cookies, you may
                        not be able to use some portions of our Service</p><br />


                    <h4>Service Providers:</h4>
                    <p>We may employ third party companies and individuals to facilitate our Service, to provide the
                        Service on our behalf, to perform Service-related services, or to assist us in analyzing how
                        our Service is used.</p><br />

                    <h4>Security:</h4>
                    <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we
                        cannot guarantee its absolute security.</p><br />


                    <h4>Links to Other Sites:</h4>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click on a
                        third party link, you will be directed to that third party's site. We strongly advise you to review
                        the Privacy Policy of every site you visit</p><br />


                    <h4>Changes to This Privacy Policy:</h4>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by
                        posting the new Privacy Policy on this page.</p><br />

                    <h4>Contact Us:</h4>
                    <p>If you have any questions about this Privacy Policy, please contact us at
                        <a href="contact@digitaltriumph.in"> contact@digitaltriumph.in</a>.</p>
                </div>
            </Container>
        </div>
    )
}

export default PrivacyPolicy;