import React from "react";
import Slider from "react-slick";
import "../Simpleslider1/Slick.css";
import "../Simpleslider1/Slick-theme.css";
import { Container } from "react-bootstrap";


export const Simpleslider1 = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear"
  };
  return (
    <div className="simpleslider1">
      <Container>
        <Slider {...settings}>
          <div>
            <div className="i1">
              <img src={require("../../Assests/clientimg/psa.png")} />
            </div>
          </div>
          <div>
            <div className="i2">
              <img src={require("../../Assests/clientimg/RLE-logo1.png")} />
            </div>
          </div>
          <div>
            <div className="i3">
              <img src={require("../../Assests/clientimg/sim-logo.jpg")} />
            </div>
          </div>
          <div>
            <div className="i4">
              <img src={require("../../Assests/clientimg/saidigital-logo.jpg")} />
            </div>
          </div>
          <div>
            <div className="i5">
              <img src={require("../../Assests/clientimg/incture.png")} />
            </div>
          </div>
        </Slider>
      </Container>
    </div>
  );
};