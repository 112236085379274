import React from "react";
import "../bannerservice/bannerservice.css"
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

function Bannerservice() {
    return (
        <div className="bannerservice">
            <Helmet>
                <title>Digital Triumph | Expert Website, Mobile App & Software Development Services</title>
                <meta name="description" content="Digital Triumph offers top-notch solutions in website development, mobile application development, and software development. Elevate your digital presence with our expert team's innovative and tailored approaches." />
                <meta name="keywords" content="Website development services in Anna Nagar,Mobile application development services in Anna Nagar,Software development services in Anna Nagar" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://digitaltriumph.in/" />
                <link rel="alternate" href="https://digitaltriumph.in/" hreflang="en" />
                <meta property="og:title" content="Digital Triumph | Expert Website, Mobile App & Software Development Services" />
                <meta property="og:description" content="Digital Triumph offers top-notch solutions in website development, mobile application development, and software development. Elevate your digital presence with our expert team's innovative and tailored approaches." />
                <meta property="og:url" content="https://digitaltriumph.in/" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div>
                            <h1>Efficiency and reliability are the cornerstones of our IT services. Let us optimize your technology, so you can focus on growing your business.</h1>
                        </div>
                    </Col>
                </Row>
                <div className="bannericons">
                    <div>
                        <img width="5%" src={require("../../Assests/icons/itservice1.gif")} />
                    </div>

                    <div>
                        <img width="5%" src={require("../../Assests/icons/business.gif")} />
                    </div>
                    <div>
                        <img width="5%" src={require("../../Assests/icons/hrsolution.gif")} />
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default Bannerservice;