import React, { useEffect } from "react";
import "./Business.css";
import Navbars from "../Navbar/Navbar";
import { Container, Row, Col } from "react-bootstrap";
import Typed from "react-typed";
import RINGS from "vanta/src/vanta.waves";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";


function Business() {
  useState(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const currentPathname = window.location.pathname;
  const newPathname = "/business";
  window.history.pushState({}, "", newPathname);

  useEffect(() => {
    RINGS({
      el: "#rings",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x24262b,
    });
  }, []);
  return (
    <div className="business">
      <Helmet>
        <title>Business - Digital Triumph</title>
        <meta name="description" content="Seamless Integration of Technology for Business Advancement." />
        <meta name="keywords" content="Business Consulting in Anna nagar,Digital Marketing Services in Anna nagar,SEO Services in Anna nagar,Search Engine Optimization Services in Anna nagar" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://digitaltriumph.in/business" />
        <link rel="alternate" href="https://digitaltriumph.in/business" hreflang="en" />
        <meta property="og:title" content=" Business - Digital Triumph " />
        <meta property="og:description" content="Seamless Integration of Technology for Business Advancement." />
        <meta property="og:url" content="https://digitaltriumph.in/business" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <div className="bannersection">
          <div style={{ height: "100vh" }} className="bg" id="rings">
            <Container>
              <Row>
                <Col lg={12} className="column">
                  <div className="bannertitle">
                    <p>Business</p>
                    <h1>
                      Seamless Integration of Technology for Business Advancement.
                    </h1>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Container>
        <Row>
          <Col>
            <p className="more">What Do We Serve ?</p>
          </Col>
        </Row>
        <Row>
          <p className="more2">
            Unlock growth possibilities with our tailored IT solutions in the business section. Elevate efficiency and innovation for your enterprise success.
          </p>
        </Row>
        <Row className="line">
          <Col lg={4}>
            <div className="web">
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-1121-iota-internet-of-things.gif")}
              />
              <p className="webs">Branding</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="web">
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-35-edit.gif")}
              />
              <p className="webs">Branding Name</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="web">
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-35-edit (1).gif")}
              />
              <p className="webs">Brand Guidelines</p>
            </div>
          </Col>
        </Row>
        <Row className="line">
          <Col lg={4}>
            <div className="web">
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-981-consultation.gif")}
              />
              <p className="webs">Strategy</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="web">
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-153-bar-chart-growth (1).gif")}
              />
              <p className="webs">Digital Marketing</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="web">
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-27-globe.gif")}
              />
              <p className="webs">S.E.O</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <div className="feat">
            <p>How We Do It?</p>
          </div>
          <div className="feat2">
            <p>Empowering business growth through our strategic expertise.</p>
          </div>
        </Row>

        <Row className="maps1">
          <Col data-aos="fade-up" className="sest" lg={6}>
            <div>
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-1121-iota-internet-of-things.gif")}
              />
              <p className="brand">Branding</p>
              <p className="brand2">
                At our IT firm, we understand the power of branding in the digital realm. Our expert team crafts cohesive brand identities that resonate, using creative design and strategic messaging to leave a lasting impression. Elevate your digital presence and stand out in the competitive landscape with our comprehensive branding solutions.
              </p>
            </div>
          </Col>
          <Col data-aos="fade-up" className="gap" style={{ padding: "0px", display: "flex", alignItems: "center" }} lg={6}>
            <img width="100%" src={require("../../Assests/icons/images.jpg")} />
          </Col>
        </Row>

        <Row className="maps1" id="itreverserow">
          <Col data-aos="fade-up" lg={6} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
            <img
              width="100%"
              src={require("../../Assests/icons/person mapping out hotel branding strategy on paper with laptop and coffee-2.jpg")}
            />
          </Col>
          <Col data-aos="fade-up" lg={6} className="sest">
            <div>
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-35-edit.gif")}
              />
              <p className="brand">Branding Name</p>
              <p className="brand2">
                Within branding's dynamic sphere, the "branding name" stands as a key symbol, capturing each collaborating enterprise's essence. Merging effortlessly with their brand, it elevates their influence in a competitive arena. This name bears weight, shaping brand identities and ensuring rapid recognition among their esteemed audience. Tailored for our IT site, the "branding name" embodies partner enterprises, including values, services, and principles.
              </p>
            </div>
          </Col>
        </Row>

        <Row data-aos="fade-up" className="maps1">
          <Col className="sest" lg={6}>
            <div>
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-35-edit (1).gif")}
              />
              <p className="brand">Branding Guidelines</p>
              <p className="brand2">
                Explore our concise branding guidelines – a roadmap for a consistent and compelling brand identity. From logo application to color palettes, fonts, and communication tone, our guidelines ensure your brand's resonance across all touchpoints. Elevate your brand's impact with our clear and effective branding directives.
              </p>
            </div>
          </Col>
          <Col data-aos="fade-up" lg={6} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
            <img
              width="100%"
              src={require("../../Assests/icons/brandingguidelines.jpg")}
            />
          </Col>
        </Row>
        <Row className="maps1" id="itreverserow">
          <Col data-aos="fade-up" lg={6} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
            <img
              width="100%"
              src={require("../../Assests/icons/stratagy.jpg")}
            />
          </Col>
          <Col data-aos="fade-up" lg={6} className="sest">
            <div>
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-981-consultation.gif")}
              />
              <p className="brand">Strategy</p>
              <p className="brand2">
                Discover our strategic prowess – where technology meets innovation to drive business success. With a focus on tailored solutions, we create IT strategies that align with your goals. Seamlessly integrate technology to elevate efficiency and growth. Unlock your potential with our strategic IT expertise.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="maps1">
          <Col data-aos="fade-up" lg={6} className="sest">
            <div>
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-153-bar-chart-growth (1).gif")}
              />
              <p className="brand">Digital Marketing</p>
              <p className="brand2">
                Experience the power of digital marketing tailored for the IT landscape. Our strategic approach leverages cutting-edge techniques to boost your online presence, attract tech-savvy audiences, and drive meaningful engagement. From SEO optimization to targeted campaigns, we navigate the digital realm to showcase your IT prowess and ensure your message resonates in the tech-savvy world.
              </p>
            </div>
          </Col>
          <Col data-aos="fade-up" lg={6} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
            <img
              width="100%"
              src={require("../../Assests/icons/digitalmarketing.jpg")}
            />
          </Col>
        </Row>
        <Row className="maps1" id="itreverserow">
          <Col data-aos="fade-up" lg={6} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
            <img
              width="100%"
              src={require("../../Assests/icons/pexels-pixabay-270637.jpg")}
            />
          </Col>
          <Col data-aos="fade-up" lg={6} className="sest">
            <div>
              <img
                width="10%"
                src={require("../../Assests/icons/wired-lineal-27-globe.gif")}
              />
              <p className="brand">S.E.O</p>
              <p className="brand2">
                Amplify your IT presence with our specialized SEO strategies. We intricately optimize your online content to secure top rankings on search engines, ensuring your IT solutions stand out in the digital arena. Our SEO expertise drives organic traffic, connecting your offerings with the right tech-driven audience and fostering lasting online visibility.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default Business;
