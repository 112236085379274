import React, { useState, useEffect } from 'react';
import "../Time/Time.css";

const Time = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatTime = (time) => {
   
    return time.toLocaleTimeString();
  };

  return (
    <div>
      <p className='time'>{formatTime(currentTime)}</p>
    </div>
  );
};

export default Time;
