import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import "../Navbar/Navbar.css"

function Navbars({setPage}) {
    return (
        <Navbar expand="lg" className="bg-body-tertiary Navbar">
            <Container>
                <Navbar.Brand onClick={()=>{setPage("home")}}><img width="130px" src={require("../../Assests/logo/logo2.png")} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">

                        <NavDropdown title="About" className="drop" id="basic-nav-dropdown">
                            <div className="final">
                                <NavDropdown.Item className="drop1" onClick={()=>{setPage("aboutus")}}>About Us <p>We can help to maintain and<br /> modernize your IT infrastructure</p></NavDropdown.Item>
                                <NavDropdown.Item className="drop2" onClick={()=>{setPage("career")}}>
                                    Career <p>Unlock a world of opportunities and growth<br /> with a career at Digital Triumph.</p>
                                </NavDropdown.Item>
                                <NavDropdown.Item className="drop3" onClick={()=>{setPage("team")}}>
                                    Team <p>Meet the faces behind our success.</p>
                                </NavDropdown.Item>
                            </div>

                        </NavDropdown>
                        <Nav.Link onClick={()=>{setPage("portfolio")}}>Portfolio</Nav.Link>

                        <Nav.Link onClick={()=>{setPage("clients")}} >Clients</Nav.Link>

                        <NavDropdown title="Services" id="basic-nav-dropdown" className="drop">
                            <div className="final">

                                <NavDropdown.Item className="drop1" onClick={()=>{setPage("itservices")}}>IT Services <p>Elevate your business with our<br /> innovative IT Services.</p></NavDropdown.Item>
                                <NavDropdown.Item className="drop2" onClick={()=>{setPage("business")}}>
                                    Business <p>Navigating your success with<br /> our strategic solutions.</p>
                                </NavDropdown.Item>
                                <NavDropdown.Item className="drop3" onClick={()=>{setPage("hrsolutions")}}>HR Solutions <p>Optimize your workforce with<br /> our Innovative HR.</p></NavDropdown.Item>
                            </div>
                        </NavDropdown>


                    </Nav>
                    <div className="navbuttondiv">
                        <Button onClick={()=>{setPage("contactus")}} className="navbutton" variant="dark">Contact</Button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navbars;