import React, { useEffect, useState } from "react";
import Navbars from "../Navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import CLOUDS from "vanta/src/vanta.net";
import "../Portfolio/Portfolio.css";
import PortfolioWebsite from "./PortfolioWebsite";
import PortfolioWebapp from "./PortfolioWebapp";
import PortfolioApp from "./PortfolioApp";
import Footer from '../Footer/Footer';
import { Helmet } from "react-helmet";



function Portfolio() {

    const [toggle, setToggle] = useState(1);

    useEffect(() => {
        CLOUDS({
            el: "#vanta",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x969696,
            backgroundColor: 0x24262b,
            blurFactor: 0.90
        })
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);





    useEffect(() => {
        // const currentPathname = window.location.pathname;
        const newPathname = "/portfolio";
        window.history.pushState({}, "", newPathname);
    }, []);


    function updateToggle(id) {
        setToggle(id)
    }

    return (
        <div className="portfolio">
            <Helmet>
                <title>Portfolio - Digital Triumph</title>
                <meta name="description" content="Transforming Ideas into Innovative Solutions." />
                <meta name="keywords" content="Portfolio Samples Digital Triumph,Website Development in Chennai ,Mobile Application Development in Chennai,Software Development in Chennai" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://digitaltriumph.in/portfolio" />
                <link rel="alternate" href="https://digitaltriumph.in/portfolio" hreflang="en" />
                <meta property="og:title" content="Portfolio - Digital Triumph" />
                <meta property="og:description" content="Transforming Ideas into Innovative Solutions." />
                <meta property="og:url" content="https://digitaltriumph.in/portfolio" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className='bannersection'>

                <div className='bg' id='vanta'>
                    <Container>
                        <Row>
                            <Col lg={12} className='column'>

                                <div className='bannertitle'>
                                    <p>Portfolio</p>
                                    <h1>Transforming Ideas into Innovative Solutions: Your Trusted Partner in IT Excellence.</h1>
                                </div>
                            </Col>

                        </Row>

                    </Container>
                </div>

            </div>

            <div className="Section">
                <div className="tab">
                    <ul className="d-flex">
                        <li className={toggle === 1 ? "heading active" : "heading"} onClick={() => updateToggle(1)}>Websites</li>
                        <li className={toggle === 3 ? "heading active" : "heading"} onClick={() => updateToggle(3)}>Web Applications</li>
                        <li className={toggle === 4 ? "heading active" : "heading"} onClick={() => updateToggle(4)}>Mobile Applications</li>
                    </ul>

                    <div className={toggle === 1 ? "show-content" : "contentss"}>
                        <div>
                            <PortfolioWebsite />

                        </div>
                    </div>

                    <div className={toggle === 3 ? "show-content" : "contentss"}>
                        <div>
                            <PortfolioWebapp />
                        </div>
                    </div>

                    <div className={toggle === 4 ? "show-content" : "contentss"}>
                        <div>
                            <PortfolioApp />

                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Portfolio;