import React from "react";
import "../HomeButton/HomeButton.css";
import { Container, Row, Col } from "react-bootstrap";


function HomeButton({setPage}) {
    return (
        <div className="Homebutton">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <div>
                            <h1>We're committed to creating lasting <br /> results for our clients.
                            </h1>
                        </div>
                        <div className="Contactbutton">
                            <a onClick={() => { setPage("contactus") }}><button>Contact Us Now</button></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HomeButton;