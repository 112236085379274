import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Clientreviews/Clientreview.css"


function ClientAnicut() {
    return (
        <div className="ClientReview">
            <Container>
                <Row>
                    <Col lg={3} className="ClientreviewDiv">
                        <div className="Clientanicut">
                            <img width="100%" src={require("../../Assests/clientimg/anicut.png")} />
                            
                        </div>
                    </Col>

                    <Col lg={9}>
                        <div>
                            <img width="10%" src={require("../../Assests/icons/5stars.png")} />
                            <p>"They helped me build our company website which enables car loans, insurance & service-oriented features they understood my requirement clearly and the output of website as expected."</p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ClientAnicut;