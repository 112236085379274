import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import "../Contactform/Contactform.css";
import { Col, Row } from 'react-bootstrap';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



export default function FormPropsTextFields({ setPage }) {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className='contactform'>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <h1 className="send">Let's talk about your project!</h1></div>
        <div>
          <form action='/mailresponse' method='post'>
            <Row>
              <TextField
                id="outlined-name-input"
                className='Name'
                label="Name"
                type="text"
                name="name"
                autoComplete="current-name"
                required
              />
            </Row>
            <Row>
              <TextField
                id="outlined-email-input"
                className='Email'
                label="Email"
                type="email"
                name="email"
                autoComplete="current-email"
                required
              />
            </Row>
            <Row>
              <TextField
                id="outlined-subject-input"
                className='Mobileno'
                label="Mobile No"
                name="mobile"
                type="text"
                autoComplete="current-MobileNo"
                required
              />
            </Row>
            <Row>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Tell us what you need help with</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tell us what you need help with"
                    className='Name'
                    name='service'
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>IT Services (Website Development, Mobile App Development, Etc )</MenuItem>
                    <MenuItem value={20}>Branding and Marketing Solutions</MenuItem>
                    <MenuItem value={30}>HR Solutions</MenuItem>
                    <MenuItem value={40}>Careers</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Row>
            <Row>
              <TextField
                id="outlined-subject-input2"
                className='Message'
                label="Message"
                name="message"
                autoComplete="current-subject"
              />
            </Row>
            <div style={{ marginTop: "10px" }} className="Contactbutton">
              <input style={{ padding: "8px 26px", border: "none", borderRadius: "6px" }} type='submit' name='submit' value='SUBMIT' />
            </div>
          </form>
        </div>
      </Box>
    </div>
  );
}