import React from "react";
import "./Flip.css";
import { Container, Row, Col } from "react-bootstrap";

function Flip() {
    return (
        <div>
            <Container>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <div className="myCard">
                            <div className="innerCard">
                                <div className="frontSide">
                                    <img
                                        src={require("../../Assests/aboutus/vision.jpg")}
                                        width="100%"
                                    />
                                </div>
                                <div className="backSide">
                                    <p className="title">Vision</p>
                                    <p>Our vision is to create a dynamic and cutting-edge technology ecosystem that embraces change, fosters innovation, and drives transformative solutions. With a relentless commitment to excellence, we envision an IT landscape that optimizes processes, enhances user experiences.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <div className="myCard">
                            <div className="innerCard">
                                <div className="frontSide">
                                    <img
                                        src={require("../../Assests/aboutus/mission.jpg")}
                                        width="100%"
                                    />
                                </div>
                                <div className="backSide">
                                    <p className="title">Mission</p>
                                    <p>Our mission as a team is to deliver exceptional technological expertise and support, enabling our organization to thrive in a rapidly evolving digital world. We strive to provide innovative solutions, impeccable service, and a secure, robust infrastructure that empowers our stakeholders to achieve their goals efficiently and effectively. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <div className="myCard">
                            <div className="innerCard">
                                <div className="frontSide">
                                    <img
                                        src={require("../../Assests/aboutus/values.jpg")}
                                        width="100%"
                                    />
                                </div>
                                <div className="backSide">
                                    <p className="title">Values</p>
                                    <p>In our company, we cultivate innovation to drive technology forward through creative culture. Collaboration blends diverse skills for holistic IT solutions. We commit to excellence, integrity, and customer success, while adaptability keeps us ahead in a dynamic tech world.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Flip;
