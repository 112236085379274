import React from "react";
import "../Aboutus/About.css";

function Blank(){
    return(
        <div className="Blank">
            {/* <h1>loading</h1> */}
        </div>
    )
}

export default Blank;