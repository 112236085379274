import React, { useState } from "react";
import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger";
import "../CounterUpPage/CounterUpPage.css"


const CounterUpPage = () => {
    const [counterOn, setCounterOn] = useState(false);

    return (
        <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
        <div className="counter">
            <h1>
               {counterOn && <CountUp start={0} end={50} duration={2} delay={0}/>}
               +
            </h1>
            </div>
            </ScrollTrigger>    
    );
};

export default CounterUpPage;