import React from "react";
import "../Contactform/Contactform.css"

function ThankYou() {
    return (
        <div className="thankyou">
            <div className="thank">
                <h1><img width="60%" src={require("../../../Assests/icons/icons8-tick-100.png")} /></h1>
                <h1>THANK YOU</h1>
                <p>Your submission has been received.</p>
                <p>Our team will contact you soon.</p>
            </div>
        </div>
    )
}

export default ThankYou;