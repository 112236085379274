import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Clientreviews/Clientreview.css"


function ClientStei() {
    return (
        <div className="ClientReview">
            <Container>
                <Row>
                    <Col lg={3} className="ClientreviewDiv">
                        <div className="Clientanicut">
                            <img width="100%" src={require("../../Assests/clientimg/steigenremovebg.png")} />

                        </div>
                    </Col>

                    <Col lg={9}>
                        <div>
                            <img width="10%" src={require("../../Assests/icons/4stars.png")} />
                            <p>"They build website for us in 4 days Awesome job.. Patience. Infinite patience. No shortcuts. Even if the client is being careless.  The best part...always solving problems with great original ideas!. Clear milestones, deadlines and fast work."</p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ClientStei;