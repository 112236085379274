import React, { useEffect, useState } from 'react'
import Portfolio from './Components/Portfolio/Portfolio';
import Clients from './Components/Clients/Clients';
import CardGridEffect from './Components/Banner/Bannersection';
import ContactUs from './Components/ContactUs/ContactUs';
import About from './Components/Aboutus/About';
import Career from './Components/Career/Career';
import Team from './Components/Team/Team';
import Itservices from './Components/Itservice/Itservice';
import Business from './Components/Business/Business';
import HRSolutions from './Components/HRSolutions/HRSolutions';
import Navbars from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import ThankYou from './Components/ContactUs/Contactform/Thankyou';
import Blank from './Components/Aboutus/Blank';
import { Route, Router, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

function App() {

// Once we change manually in addressbar, we set the session storage to redirect the page
  const currentUrl = document.location.pathname
  sessionStorage.setItem("currentPage",  currentUrl == "/" ? 'home' : currentUrl.slice(currentUrl.indexOf('/') + 1));

  const storedPage = sessionStorage.getItem("currentPage");
  const [page, setPage] = useState(storedPage == null ? 'home' : storedPage)

  const handlePageChange = (newPage) => {
    setPage(newPage);
    sessionStorage.setItem("currentPage", newPage);
  };
  
  return (
    <>
      <Navbars setPage={handlePageChange} />
      {page === "home" && <CardGridEffect setPage={setPage} />}
      {page === "portfolio" && <Portfolio />}
      {page === "aboutus" && <About setPage={setPage} />}
      {page === "career" && <Career setPage={setPage} />}
      {page === "team" && <Team setPage={setPage} />}
      {page === "clients" && <Clients />}
      {page === "itservices" && <Itservices setPage={setPage} />}
      {page === "business" && <Business />}
      {page === "hrsolutions" && <HRSolutions setPage={setPage} />}
      {page === "contactus" && <ContactUs setPage={setPage} />}
      {page === "privacypolicy" && <PrivacyPolicy setPage={setPage} />}
      {page === "thankyou" && <ThankYou setPage={setPage} />}
      {page === "blank" && <Blank setPage={setPage} />}
      <Footer setPage={handlePageChange} />
    </>

  )
}

export default App;