import React, {useState} from "react";
import "../Accordion/Accordion.css"
import { Container, Row, Col } from "react-bootstrap";


function Accordion() {

    const [selected, setSelected] = useState(null)

    const toggle = (i) =>{
        if (selected === i){
            return setSelected(null)
        }

        setSelected(i)
    }


    return (
        <div className="Accordionmain">
            <div>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className="QuesAndAns">
                                <div className="accordion">
                                    {
                                        Accor.map((item, i) => (
                                            <div className="item">
                                                <div className="ques" onClick={()=> toggle(i)}>
                                                    <h1>{item.question}</h1>
                                                    <span>{selected === i ? "-" : "+"}</span>
                                                </div>
                                                <div className={selected === i ? "ans show" : "ans"}>
                                                {item.answer}
                                                </div>
                                                <hr/>
                                            </div>
                                        ))}

                                </div>
                            </div>
                            
                        </Col>
                        <Col lg={6}>
                            <div className="question">
                                <img width="100%" src={require("../../Assests/icons/questionimages.jpg")} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}


const Accor = [
    {
        question: "What services does your company offer in the IT domain?",
        answer: " At our company, we provide a comprehensive range of services in the IT domain. This includes developing cutting-edge mobile and web applications, offering efficient HR solutions, and catering to both IT and non-IT staffing needs. Our expertise spans across various sectors to meet your diverse technological and human resource requirements.",
    },
    {
        question: "Tell me about your HR solutions. How can they optimize my workforce management?",
        answer: "Our HR solutions are geared towards optimizing your workforce management. From talent acquisition and onboarding to performance management and training, we offer a holistic approach to human resource processes. Our solutions not only save time and resources but also foster employee growth and retention, contributing to a more productive and satisfied workforce.",
    },
    {
        question: "How do you tailor your solutions to suit different industry verticals?",
        answer: "We understand that each industry has unique challenges and requirements. That's why we take a consultative approach, deeply understanding your industry's nuances before crafting solutions. Whether it's healthcare, finance, e-commerce, or any other sector, we ensure our solutions and staffing services are aligned with your industry's specific needs, regulations, and best practices.",
    },
    {
        question: "What's the process for engaging your IT and HR services?",
        answer: "Engaging our services is a straightforward process. We begin with a comprehensive consultation to grasp your needs and objectives. From there, we devise tailored strategies, create timelines, and execute the plan with utmost efficiency. Whether it's building a mobile app, finding the right talent, or implementing HR solutions, we guide you through each step to ensure a seamless experience.",
    }
]

export default Accordion;