import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import "../Portfolio/Portfolio.css";



function PortfolioWebapp() {
    return (
        <div className="portfoliowebapp">
            <Container>
                <Row className="ports">
                    <Col>
                        <div>
                            <h1>Welcome to Digital Triumph, a leading IT company that specializes in providing cutting-edge solutions for websites, e-commerce sites, custom ERP, and CRM applications. With a team of skilled professionals and a passion for innovation, we strive to deliver high-quality products and services that cater to our clients' unique business needs. Our portfolio showcases some of the remarkable projects we have successfully delivered, reflecting our commitment to excellence and customer satisfaction.</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="portfoliowebapprow">
                    <Col lg={6} className="portfoliowebappcol">
                        <Carousel fade>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/design1.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/design2.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/design3.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/design4.png")} />
                            </Carousel.Item>
                        </Carousel>
                    </Col>


                    <Col lg={6} className="portfoliocol1">
                        <div>
                            <h1>DESIGN INFINITY</h1>
                            <p>Design Infinity stands as a prominent architecture and interiors company based in Chennai. Our team has successfully crafted a tailored web application that serves the purpose of overseeing and facilitating updates for their ongoing projects. This intuitive platform empowers them to effortlessly access project details, perform updates, and maintain a comprehensive overview of their project's progress.</p>
                        </div>
                    </Col>
                </Row>


                <Row className="portfoliowebapprow" id="itreverserow">
                    <Col lg={6} className="portfoliocol1">
                        <div>
                            <h1>LOGISTICS SOFTWARE</h1>
                            <p>DT ExIm stands as a web application designed for customs agents, facilitating effortless management of clearing, forwarding, and comprehensive customs paperwork. This platform ensures convenience by allowing access from any location and at any time, streamlining the customs documentation process effectively.</p>
                        </div>
                    </Col>

                    <Col lg={6} className="portfoliowebappcol">
                        <Carousel fade>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/dtexim4.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/dtexim5.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/dtexim6.png")} />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>


                <Row className="portfoliowebapprow">
                    <Col lg={6} className="portfoliowebappcol">
                        <Carousel fade>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/jobportal1.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/jobportal2.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="100%" src={require("../../Assests/Portfoliowebappimages/jobpportal3.png")} />
                            </Carousel.Item>
                        </Carousel>
                    </Col>

                    <Col lg={6} className="portfoliocol1">
                        <div>
                            <h1>HR PORTAL</h1>
                            <p>EMPLOI automates resume parsing, extracting key details for recruiters. It simplifies job posting distribution and centralizes candidate data. This tool tracks progress and aids communication, while providing insights through analytics. EMPLOI ensures streamlined recruitment with data protection compliance.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PortfolioWebapp;