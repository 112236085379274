import React from "react";
import Slider from "react-slick";
import "../SimpleSlider/Slick.css";
import "./Slick-theme.css";
import { Container } from "react-bootstrap";


export const SimpleSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 2500,
    cssEase: "linear"
  };
  return (
    <div className="Slidersss">
      <Container>
        <Slider {...settings}>
          <div>
            <div className="i1">

              <img src={require("../../Assests/clientimg/anicut.png")} />
            </div>
          </div>
          <div>
            <div className="i2">
              <img src={require("../../Assests/clientimg/srimotors-logo.png")} />
            </div>

          </div>

          <div>

            <div className="i3">
              <img src={require("../../Assests/clientimg/smartshift.png")} />
              
            </div>
          </div>

          <div>

            <div className="i4">
              <img src={require("../../Assests/clientimg/pridegen-logo.jpg")} />
            </div>
           
          </div>
          <div>
            <div className="i4">
              <img src={require("../../Assests/clientimg/Logo-carsnloans.png")} />
            </div>
          </div>
          <div>
            <div className="i5">
              <img src={require("../../Assests/clientimg/spry-coat.png")} />
            </div>
          </div>
          <div>
            <div className="i6">
              <img src={require("../../Assests/clientimg/yamaha.png")} />
            </div>
          </div>
          <div>
            <div className="i7">
              <img src={require("../../Assests/clientimg/dynamic.png")} />
            </div>
          </div>
          <div>
            <div className="i8">
              <img src={require("../../Assests/clientimg/inoaura.png")} />
            </div>
          </div>
          <div>
            <div className="i7">
              <img src={require("../../Assests/clientimg/sureshbalachandar.jpg")} />
            </div>
          </div>
          <div>
            <div className="i7">
              <img src={require("../../Assests/clientimg/steigen.png")} />
            </div>
          </div>
          <div>
            <div className="i7">
              <img src={require("../../Assests/clientimg/Brand-Mascot-logo.jpg")} />
            </div>
          </div>
          <div>
            <div className="i7">
              <img src={require("../../Assests/clientimg/joyfullkids-logo.jpg")} />
            </div>
          </div>
          <div>
            <div className="i7">
              <img src={require("../../Assests/clientimg/krishpar-logo.jpg")} />
            </div>
          </div>
          <div>
            <div className="i9">
              <img src={require("../../Assests/clientimg/zakapps.jpg")} />
            </div>
          </div>
          <div>
            <div className="i10">
              <img src={require("../../Assests/clientimg/mytat.jpg")} />
            </div>
          </div>
          <div>
            <div className="i11">
              <img src={require("../../Assests/clientimg/india.jpg")} />
            </div>
          </div>
          <div>
            <div className="i11">
              <img src={require("../../Assests/clientimg/UnionSys.png")} />
            </div>
          </div>


        </Slider>
      </Container>
    </div>
  );
};