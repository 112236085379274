import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import "../Dubai/color.css";

const Belgium = () => {
  const [BelgiumTime, setBelgiumTime] = useState(moment().tz('Europe/Brussels'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBelgiumTime(moment().tz('Europe/Brussels'));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatTime = (time) => {
    return time.format('HH:mm:ss A');
  };

  return (
    <div>
      <p className='belgium'>{formatTime(BelgiumTime)}</p>
    </div>
  );
};

export default Belgium;
