import React, { useEffect } from 'react';
import Datamap from 'datamaps';

const MapComponent = () => {
    useEffect(() => {
        const map = new Datamap({
            element: document.getElementById('container'),
            scope: 'world',
            fills: {
                'MAJOR': 'red',
                'MEDIUM': '#0fa0fa',
                'MINOR': '#bada55',
                'MED': "green",
                defaultFill: '#dddddd'
            },
        });

        map.bubbles([
            {
                name: 'Belgium',
                radius: 10,
                latitude: 50.50,
                longitude: 4.46,
                address: "10A, A Block, BC Apartments",
                area: "Guindy",
                fillKey: 'MINOR',
            },
            {
                name: 'India',
                radius: 10,
                latitude: 20.59,
                longitude: 78.96,
                address: "65J, G Block, IG Road",
                area: "Guindy",
                fillKey: 'MAJOR'
            },
            {
                name: 'Dubai',
                radius: 10,
                latitude: 25.20,
                longitude: 55.27,
                address: "88A, Fahad Street, Pix Road",
                area: "Guindy",
                fillKey: 'MEDIUM',
            },
            {
                name: 'Singapore',
                radius: 10,
                latitude: 1.35,
                longitude: 103.81,
                address: "88A, Fahad Street, Pix Road",
                area: "Guindy",
                fillKey: 'MED',
            }
        ], {
            popupTemplate: function (geo, data) {
                return '<div class="hoverinfo"><h4>' + data.name;
            }
        });
    }, []);

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div id="container" style={{ position: 'relative', width: '60%', height: '300px' }}></div>
        </div>
    );
};

export default MapComponent;
