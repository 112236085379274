import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "../Banner/Bannersection.css";
import Bannerservice from '../bannerservice/bannerservice';
import AnimatedGallery from '../Bannertech/BannerTech';
import { SimpleSlider } from '../SimpleSlider/Slidee';
import Map from '../Map/Map';
import Technologies from '../Technologies/Technologies';
import SimpleSliders from '../SliderCarousel/SliderCarousel';
import Accordion from '../Accordion/Accordion';
import HomeButton from '../HomeButton/HomeButton';
import CLOUDS from "vanta/src/vanta.net";
import { Helmet } from 'react-helmet';



function CardGridEffect({ setPage }) {
    const [currentDot, setCurrentDot] = useState('');

    const currentPathname = window.location.pathname;
    const newPathname = "/home";
    window.history.pushState({}, "", newPathname);

    const handleDotHover = (event) => {
        const ele = event.target;
        const row = parseInt(ele.getAttribute('dot-row'));
        const col = parseInt(ele.getAttribute('dot-col'));
        ele.classList.toggle('current');

        const step = 1;
        document
            .querySelectorAll(
                `[dot-row="${row + step}"][dot-col="${col}"], [dot-row="${row}"][dot-col="${col + step}"], [dot-row="${row - step}"][dot-col="${col}"], [dot-row="${row}"][dot-col="${col - step}"]`
            )
            .forEach((selector) => {
                selector.classList.toggle('currentstep' + step);
            });

        document
            .querySelectorAll(
                `[dot-row="${row + step}"][dot-col="${col + step}"], [dot-row="${row + step}"][dot-col="${col - step}"], [dot-row="${row - step}"][dot-col="${col + step}"], [dot-row="${row - step}"][dot-col="${col - step}"]`
            )
            .forEach((selector) => {
                selector.classList.toggle('currentstep' + (step + 1));
            });
    };

    const generateDots = () => {
        const dots = [];
        let r = 1;

        for (let i = 1; i <= 100; i++) {
            const c = i % 10;
            const dotRow = r;
            const dotCol = c === 0 ? 10 : c;
            const dotId = `dot-${i}`;

            dots.push(
                <a
                    key={dotId}
                    id={dotId}
                    dot-row={dotRow}
                    dot-col={dotCol}
                    className={`card__grid-effect-tile `}
                    href="#"
                    onMouseEnter={handleDotHover}
                    onMouseLeave={handleDotHover}
                ></a>
            );

            if (i % 10 === 0) {
                r++;
            }
        }

        return dots;
    };

    useEffect(() => {
        CLOUDS({
            el: "#vanta",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x969696,
            backgroundColor: 0x24262b,
            blurFactor: 0.90
        })
    }, [])

    return (
        <div className='bannersections'>
            {/* <Cursor/> */}
            <Helmet>
                <title>Home - Experience the difference with our cutting-edge IT solutions.</title>
                <meta name="description" content="Experience the difference with our cutting-edge IT solutions." />

            </Helmet>


            <div className='bannersection'>

                <div className='bg' id='vanta'>
                    <Container>
                        <Row>
                            <Col lg={12} className='column'>

                                <div className='bannertitle'>
                                    <h1>Experience the difference with our cutting-edge IT solutions.</h1><br />
                                    <p>At Digital Triumph, we empower enterprises to enhance their worth through tailored software development, product design, quality assurance, and consultancy services.</p>

                                </div>
                            </Col>

                        </Row>

                    </Container>
                </div>

            </div>

            <div>
                <Bannerservice />
            </div>

            <div>
                <AnimatedGallery />
            </div>

            <div>
                <Map />
            </div>


            <div>
                <SimpleSliders />
            </div>

            <div>
                <Accordion />
            </div>

            <div>
                <HomeButton setPage={setPage} />
            </div>





        </div >
    );
};

export default CardGridEffect;
