import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../SliderCarousel/SliderCarousel.css"
import ClientAnicut from "../Clientreviews/Clientanicut";
import ClientSrimotors from "../Clientreviews/Clientsrimotors";
import { Container } from "react-bootstrap";
import ClientStei from "../Clientreviews/ClientStei";


export default function SimpleSliders() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
  };
  return (
    <Container>
      <div className="SimpleSliders">
        <h1>What Our Clients Say</h1>
        <Slider {...settings}>
          <div>
            <div>
              <ClientSrimotors />
            </div>
          </div>
          <div>
            <ClientAnicut />
          </div>
          <div>
            <ClientStei />
          </div>
          
        </Slider>

      </div>
    </Container>
  );
}